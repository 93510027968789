<pe-main-header class="main-header" [designName]="title" [designRegion]="commonRegion" [beforeLogout]="beforeLogout"
    [openDesignSettings]="openDesignSettings" [openSaveAsTemplate]="openSaveAsTemplate" [startTour]="startTour"
    [openGeneralNotes]="openGeneralNotes" (menuOpened)="menuOpened()"
    (hiltiDataPrivacyUrlOpened)="hiltiDataPrivacyUrlOpened()" (regionLinkOpened)="regionLinkOpened()">
</pe-main-header>

<div class="main-content">
    <div class="main-content-left" [class.width-extended]="hasExtendedWidth"
        [class.main-content-left-hidden]="hideLeftMenu">
        <div id="main-content-left-container" class="main-content-left-container">
            <div class="main-content-left-black-bar"></div>
            <pe-main-menu #mainMenuRef
                class="menu-container"
                [disableDragToMoveText]="false"
                [tabsHidden]="hideLeftMenu"
                [moduleLocalizationService]="localizationService"
                *ngIf="selectedMenu != null">
            </pe-main-menu>
        </div>
    </div>

    <div class="main-content-center-right" #mainContentCenterRightRef>
        <div class="main-content-center-right-top" #mainContentCenterRightTopRef>
            <div class="main-content-center">
                <pe-button id="hide-left-menu" class="hide-menu-button hide-menu-button-left" [look]="'DefaultIcon'"
                    (buttonClick)="toggleLeftMenu()"
                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.CollapseMenu' | l10n: selectedLanguage">
                    <span class="pe-ui-glass-sprite hide-arrow-sprite"
                        [class.pe-ui-glass-sprite-arrow-left-medium]="!hideLeftMenu"
                        [class.pe-ui-glass-sprite-arrow-right-medium]="hideLeftMenu"></span>
                </pe-button>

                <pe-button id="hide-right-menu" class="hide-menu-button hide-menu-button-right" [look]="'DefaultIcon'"
                    (buttonClick)="toggleRightMenu()"
                    [ngbTooltip]="'Agito.Hilti.Profis3.Main.CollapseMenu' | l10n: selectedLanguage">
                    <span class="pe-ui-glass-sprite hide-arrow-sprite"
                        [class.pe-ui-glass-sprite-arrow-right-medium]="!hideRightMenu"
                        [class.pe-ui-glass-sprite-arrow-left-medium]="hideRightMenu"></span>
                </pe-button>

                <div class="main-content-position">
                    <div class="model-view-options click-through">
                        <div class="options-left">
                            <div class="button-group undo-redo-section click-reset" role="group">
                                <div class="buttons join">
                                    <div class="tooltip-wrapper" [ngbTooltip]="undoTooltip" placement="auto top">
                                        <pe-button id="main-undo-button" [look]="'DefaultIcon'" (buttonClick)="undo()"
                                            [disabled]="!canUndo()">
                                            <span class="pe-ui-glass-sprite pe-ui-glass-sprite-undo"></span>
                                        </pe-button>
                                    </div>
                                    <div class="tooltip-wrapper" [ngbTooltip]="redoTooltip" placement="auto top">
                                        <pe-button id="main-redo-button" [look]="'DefaultIcon'" (buttonClick)="redo()"
                                            [disabled]="!canRedo()">
                                            <span class="pe-ui-glass-sprite pe-ui-glass-sprite-redo"></span>
                                        </pe-button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="options-left">
                            <pe-loading class="update-pending" [class.show]="updatePending"
                                [color]="'#524f53'"></pe-loading>
                        </div>
                    </div>

                    <div class="model-view-container">
                        <img class="main-image" [src]="mainImage" />
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="main-content-right-sidebar" *ngIf="!hideRightMenu">
        <div class="main-content-right" *ngIf="rightSideLoaded">
            <div class="main-content-right-container">
                <div class="main-content-right-scroll">
                    <div class="design-section-wrapper">
                        <app-design-section #designSectionRef
                            [designDetails]="designDetails"
                            [trackingDetails]="trackingDetails"
                            [propertyChange]="propertyChange">
                        </app-design-section>
                    </div>

                    <div class="main-content-right-util"
                        sortablejs [sortablejsOptions]="sortableMenu3DRightOptions"
                        (sortablejsInit)="sortMenu3DRight($any($event))">

                        <div [attr.data-id]="CollapsingControls.DesignResults">
                            <app-design-results
                                *ngIf="designDetails.calculationResult?.kernelResult != null"
                                [numberOfPucks]="designDetails.calculationResult?.kernelResult?.numberOfPucks"
                                [maxSpacingPucks]="designDetails.calculationResult?.kernelResult?.maxSpacingPucks"
                                [totalFreeSpace]="designDetails.calculationResult?.kernelResult?.totalFreeSpace"
                                [distancePucks]="designDetails.calculationResult?.kernelResult?.distancePucks"
                                [cartridges330]="designDetails.calculationResult?.kernelResult?.cartridges330"
                                [cartridges500]="designDetails.calculationResult?.kernelResult?.cartridges500"
                            >
                            </app-design-results>
                        </div>

                        <div [attr.data-id]="CollapsingControls.NotificationsDesign">
                            <app-notifications
                                [isVisible]="notificationComponentInputs.isVisible()"
                                [inputs]="notificationComponentInputs"
                            >
                            </app-notifications>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
