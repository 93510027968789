import { Injectable } from '@angular/core';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { IGetStringOptions, ISanitizeTags, LocalizationServiceBase, LocalizationServiceInjected } from '@profis-engineering/pe-ui-common/services/localization.common';
import { environment } from '../../environments/environment';

type Translations = Partial<Record<string, string>>;
type LanguageTranslations = Partial<Record<string, Translations>>;

interface LocalizationServiceBaseInternal extends LocalizationServiceBase {
    _translations: Record<string, string>;
    getTranslations(language: string, options?: ApiOptions): Promise<void>;
}

const reportTranslationsStartKeys = [
    'Glass.',
];

@Injectable({
  providedIn: 'root'
})
export class LocalizationService extends LocalizationServiceInjected {
    declare baseService: LocalizationServiceBaseInternal;

    private translations?: Translations;
    private missingKeys: Record<string, undefined> = {};

    private languageTranslations: LanguageTranslations = {};

    public override setBaseService(baseService: LocalizationServiceBase): void {
        this.baseService = baseService as LocalizationServiceBaseInternal;

        this.sanitizeText = baseService.sanitizeText.bind(baseService);
        this.numberFormat = baseService.numberFormat.bind(baseService);
        this.isHtml = baseService.isHtml.bind(baseService);
        this.moment = baseService.moment.bind(baseService);
        this.selectTranslations = baseService.selectTranslations.bind(baseService);
        this.addGetTranslationsHook = baseService.addGetTranslationsHook?.bind(baseService);

        const loadTranslations = this.loadTranslations.bind(this);
        if (this.addGetTranslationsHook) {
            this.addGetTranslationsHook(loadTranslations);
        }
        // TODO - this is implemented this way to support old and new version of common localization service
        // we should remove this once we no longer have to support old version
        else {

            // getTranslations is called when language is changed

            // pe-ui is still not modularized
            // here we are changing the base service that is used by everyone so we have to be careful
            const baseGetTranslations = this.baseService.getTranslations;
            this.baseService.getTranslations = async function (language: string, options?: ApiOptions): Promise<void> {
                const [baseTranslationsResult, translationsResult] = await Promise.allSettled([
                    baseGetTranslations.apply(this, [language, options]),
                    loadTranslations(language),
                ]);

                if (translationsResult.status == 'rejected') {
                    console.error(translationsResult.reason);
                }

                if (baseTranslationsResult.status == 'rejected') {
                    throw new Error(baseTranslationsResult.reason);
                }
            };
        }
    }

    public async loadTranslations(language = this.selectedLanguage): Promise<void> {
        // translations might already be loaded from before
        const currentTranslations = this.languageTranslations[language];
        if (currentTranslations != null) {
            this.translations = currentTranslations;
            return;
        }

        // load translations
        try {
            const translations = await fetchTranslations(`translation_${language}.json`);

            // mising translations, try en-US
            if (translations == null) {
                if (language != 'en-US') {
                    await this.loadTranslations('en-US');
                    return;
                }

                // even en-US is missing
                // clear all translations so we get keys as values
                this.translations = {};
                return;
            }

            // save translations
            this.languageTranslations[language] = this.translations = translations;
        }
        catch (error) {
            console.error(error);

            // clear all translations so we get keys as values
            this.translations = {};
            return;
        }

        async function fetchTranslations(name: string): Promise<Translations | undefined> {
            const urlName = environment.manifest != null
                ? environment.manifest[name]
                : name;

            // return undefined for missing translations
            // undefined return will default to en-us language
            if (urlName == null) {
                console.warn(`Translation manifest for ${name} not found`);
                return undefined;
            }

            const response = await fetch(`cdn/pe-ui-glass/translations/${urlName}`, {
                method: 'GET',
                // no-cache in local debug where we don't build the manifest file
                cache: environment.manifest != null ? undefined : 'no-cache'
            });

            // we have translations
            if (response.ok) {
                return (await response.json()) ?? {};
            }

            // missing translations
            if (response.status == 404) {
                return undefined;
            }

            // error
            throw new Error('loadTranslations error response', { cause: response });
        }
    }

    public filterReportTranslations(): Record<string, string> {
        const translations = this.translations ?? {};
        const baseTranslations = this.baseService._translations;

        const entries = Object.entries({ ...baseTranslations, ...translations });
        const filteredEntries = entries.filter(([key]) => reportTranslationsStartKeys.some(reportKey => key.startsWith(reportKey)));
        const mappedEntries = filteredEntries.map(([key, value]) => [key, value]);

        return Object.fromEntries(mappedEntries);
    }

    /**
     * @deprecated use getString
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getLocalizedStringByCulture(key: string, culture: string, tags?: ISanitizeTags | undefined): string {
        throw new Error('getLocalizedStringByCulture is deprecated - use getString');
    }


    public override getString(key: string, opts?: IGetStringOptions | undefined): string {
        if (key == null) {
            return undefined!;
        }

        const translation = this.translations?.[key] ?? this.baseService.getString(key, { optional: true });

        if (translation == null) {
            if (opts?.optional) {
                return undefined!;
            }

            if (!(key in this.missingKeys)) {
                this.missingKeys[key] = undefined;
                console.warn(`Missing localized string: ${key}`);
            }

            return `#?${key}?#`;
        }

        if (opts?.tags != null) {
            return this.sanitizeText(translation, opts.tags);
        }

        return translation;
    }

    public override getKeyExists(key: string): boolean {
        return this.translations?.[key] != null || this.baseService.getKeyExists(key);
    }

    public override hasTranslation(key: string): boolean {
        if (key == null || key.trim() == '' || !this.getKeyExists(key)) {
            return false;
        }

        const translation = this.getString(key, { optional: true });
        return translation != null && translation.trim() != '';
    }
}
