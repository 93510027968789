import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BrowserService } from './browser.service';
import { AppData } from './data.service';
import { ApiDesignCreateRequest, ApiDesignReportGenerateOptions, ApiDesignUpdateRequest, ApiDesignUpdateResponse, CalculationResult, ConvertAndCalculateResult, CreateAndCalculateResult, DesignDetailsData, DesignServiceUpdateDesignOptions, ProjectDesign, UpdateAndCalculateResult } from './design.service';
import { LocalizationService } from './localization.service';

interface InitializeOptions {
    validateScopes?: boolean;
    includeScopeCheckNameInMessage?: boolean;
    showDevVersionTextInReport: boolean;
    disableStaticContentHashInReport: boolean;
}

interface ApiAddTranslationsInput {
    languageId: string;
    translations: Record<string, string>;
}

type ApiSetTemplatesInput = Record<string, Record<string, string>>;

interface TemplateDetails {
    templatePath: string;
    template: string;
}

function getHashUrl(uri: string): string {
    const dotnetManifest = (window as any).dotnetManifestGlass as Record<string, string> | undefined;
    if (dotnetManifest != null) {
        const hash = dotnetManifest[uri];
        const extensionIndex = uri.lastIndexOf('.');
        const extension = uri.substring(extensionIndex + 1);
        const nameWithoutExtension = uri.substring(0, extensionIndex);

        return `${nameWithoutExtension}.${hash}.${extension}`;
    }

    return uri;
}

@Injectable({
    providedIn: 'root'
})
export class DotnetService {
    private exports: any;
    private dotnetInitializedPromise?: Promise<void>;

    private addedTranslationNames: Record<string, null> = {};
    private templatesAdded = false;

    constructor(
        private browserService: BrowserService,
        private localizationService: LocalizationService
    ) {}

    public api = {
        app: {
            data: async (): Promise<AppData> => {
                // load AppData from S3
                // this way we do not need to load .NET at the app start
                const disableNoCacheFetch = this.getDisableNoCacheFetch();

                const response = await fetch(`cdn/pe-ui-glass/dotnet/${getHashUrl('data.json')}`, {
                    method: 'GET',
                    cache: disableNoCacheFetch ? undefined : 'no-cache'
                });
                if (!response.ok) {
                    throw new Error('glass data.json error response', { cause: response });
                }

                return await response.json();
            }
        },
        design: {
            convert: async (projectDesign: ProjectDesign): Promise<ProjectDesign> => {
                await this.initialize();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.DesignApiConvert(JSON.stringify(projectDesign)));
            },
            create: async (designCreateRequest: ApiDesignCreateRequest): Promise<ProjectDesign> => {
                await this.initialize();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.DesignApiCreate(JSON.stringify(designCreateRequest)));
            },
            details: async (projectDesign: ProjectDesign): Promise<DesignDetailsData> => {
                await this.initialize();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.DesignApiDetails(JSON.stringify(projectDesign)));
            },
            update: async (updateDesignOptions: DesignServiceUpdateDesignOptions): Promise<ApiDesignUpdateResponse> => {
                await this.initialize();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.DesignApiUpdate(JSON.stringify(updateDesignOptions)));
            }
        },
        calculation: {
            calculate: async (projectDesign: ProjectDesign): Promise<CalculationResult> => {
                await this.initialize();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.CalculationApiCalculate(JSON.stringify(projectDesign)));
            }
        },
        report: {
            generateHtml: async (designReportGenerateOptions: ApiDesignReportGenerateOptions): Promise<string> => {
                if (this.dotnetInitializedPromise == null) {
                    throw new Error('dotnet not started');
                }
                await this.dotnetInitializedPromise;

                this.addReportLanguage(designReportGenerateOptions.localization.language);
                await this.addReportTemplates();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.ReportApiGenerateHtml(JSON.stringify(designReportGenerateOptions)));
            }
        },
        core: {
            createAndCalculate: async (designCreateRequest: ApiDesignCreateRequest): Promise<CreateAndCalculateResult> => {
                await this.initialize();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.CoreApiCreateAndCalculate(JSON.stringify(designCreateRequest)));
            },
            convertAndCalculate: async (projectDesign: ProjectDesign): Promise<ConvertAndCalculateResult> => {
                await this.initialize();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.CoreApiConvertAndCalculate(JSON.stringify(projectDesign)));
            },
            updateAndCalculate: async (designUpdateRequest: ApiDesignUpdateRequest): Promise<UpdateAndCalculateResult> => {
                await this.initialize();

                return JSON.parse(this.exports.Hilti.Glass.Wasm.Program.CoreApiUpdateAndCalculate(JSON.stringify(designUpdateRequest)));
            }
        }
    };

    private getDisableNoCacheFetch(): boolean {
        return (window as any).dotnetManifestGlass ?? false;
    }

    private async initializeDotnet(): Promise<void> {
        const disableNoCacheFetch = this.getDisableNoCacheFetch();

        // start loading data
        const dataPromise = fetch(`cdn/pe-ui-glass/dotnet/${getHashUrl('data.dat')}`, {
            method: 'GET',
            cache: disableNoCacheFetch ? undefined : 'no-cache'
        });

        // load runtime
        const { dotnet } = await import(/* webpackIgnore: true */`./dotnet/${getHashUrl('_framework/dotnet.js')}` as string) as typeof import('../../types/dotnet');

        // load resources and start runtime
        const { getAssemblyExports, getConfig } = await dotnet
            .withConfig({
                disableNoCacheFetch
            })
            .withResourceLoader((type, name, defaultUri) => {
                const frameworkIndex = defaultUri.lastIndexOf('/_framework/') + 1;
                const frameworkUri = defaultUri.substring(frameworkIndex);
                const baseUri = defaultUri.substring(0, frameworkIndex);
                const hashUri = getHashUrl(frameworkUri);

                const uri = `${baseUri}${hashUri}`;

                // disableNoCacheFetch for blazor.boot.json
                if (defaultUri.endsWith('/blazor.boot.json')) {
                    return fetch(uri, {
                        method: 'GET',
                        cache: disableNoCacheFetch ? undefined : 'no-cache'
                    });
                }

                return uri;
            })
            .create();
        const config = getConfig();

        this.exports = await getAssemblyExports(config.mainAssemblyName!);

        // wait for data load
        const dataResponse = await dataPromise;
        if (!dataResponse.ok) {
            throw new Error('glass data.dat error response', { cause: dataResponse });
        }

        const dataCacheBlob = await dataResponse.blob();
        const dataCacheBase64 = await this.browserService.blobToBase64(dataCacheBlob);

        // initialize with data
        const initializeOptions: InitializeOptions = {
            validateScopes: environment.dotnetValidateScopes,
            includeScopeCheckNameInMessage: environment.includeScopeCheckNameInMessage,
            showDevVersionTextInReport: environment.showDevVersionTextInReport,
            disableStaticContentHashInReport: environment.disableStaticContentHashInReport
        };
        this.exports.Hilti.Glass.Wasm.Program.Initialize(dataCacheBase64, JSON.stringify(initializeOptions));
    }

    private async initialize(): Promise<void> {
        if (this.dotnetInitializedPromise == null) {
            this.dotnetInitializedPromise = this.initializeDotnet();
        }
        await this.dotnetInitializedPromise;
    }

    private addReportLanguage(language: string): void {
        // add translations to wasm if not already added
        if (!(language in this.addedTranslationNames)) {
            const addTranslationsInput: ApiAddTranslationsInput = {
                languageId: language,
                translations: this.localizationService.filterReportTranslations(),
            };
            this.exports.Hilti.Glass.Wasm.Program.ReportApiAddTranslations(JSON.stringify(addTranslationsInput));

            this.addedTranslationNames[language] = null;
        }
    }

    private async addReportTemplates(): Promise<void> {
        // add templates to wasm if not already added
        if (!this.templatesAdded) {
            const templatesPath: string[] = JSON.parse(this.exports.Hilti.Glass.Wasm.Program.ReportApiGetTemplatesPath());

            const setTemplatesInput = await this.fetchAllTemplates(templatesPath);
            this.exports.Hilti.Glass.Wasm.Program.ReportApiSetTemplates(JSON.stringify(setTemplatesInput));

            this.templatesAdded = true;
        }
    }

    private async fetchAllTemplates(templatesPath: string[]): Promise<ApiSetTemplatesInput> {
        const disableNoCacheFetch = this.getDisableNoCacheFetch();
        const fetchTemplatePromises: Promise<TemplateDetails>[] = [];

        for (const templatePath of templatesPath) {
            fetchTemplatePromises.push(this.fetchTemplate(disableNoCacheFetch, templatePath));
        }

        const resolvedTemplatePromises = await Promise.allSettled(fetchTemplatePromises);

        const setTemplatesInput: ApiSetTemplatesInput = {};
        for (const resolvedTemplatePromise of resolvedTemplatePromises) {
            if (resolvedTemplatePromise.status == 'rejected') {
                throw new Error(resolvedTemplatePromise.reason);
            }

            const templateDetails = resolvedTemplatePromise.value;
            const templatePath = templateDetails.templatePath;
            const templateNameIndex = templatePath.lastIndexOf('/');
            const templateDirectory = templatePath.substring(0, templateNameIndex);
            const templateName = templatePath.substring(templateNameIndex + 1);

            const templatesDirectoryDetails = setTemplatesInput[templateDirectory] = setTemplatesInput[templateDirectory] ?? {};
            templatesDirectoryDetails[templateName] = templateDetails.template;
        }

        return setTemplatesInput;
    }

    private async fetchTemplate(disableNoCacheFetch: boolean, templatePath: string): Promise<TemplateDetails> {
        const response = await fetch(`cdn/pe-ui-glass/dotnet/${getHashUrl(`${templatePath}`)}`, {
            method: 'GET',
            cache: disableNoCacheFetch ? undefined : 'no-cache'
        });

        if (!response.ok) throw new Error(`Failed to fetch template: ${templatePath} - ${response.statusText}`);

        const template = await response.text();

        return {
            templatePath,
            template
        };
    }
}
