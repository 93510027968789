import { Injectable } from '@angular/core';
import { Menu, Region, Tab } from './static-menu.definitions';
import { NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { getSpriteAsIconStyle } from '../sprites';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

@Injectable({
    providedIn: 'root'
})
export class StaticMenuService {
    private menu?: Menu;

    public getMenu() {
        if (this.menu == null) {
            this.menu = this.createMenu();
            this.fillControlProperties(this.menu);
        }

        return this.menu;
    }

    // TODO TEAM: remove this function and set the Size and TooltipType on all controls that need it
    private fillControlProperties(menu: Menu): void {
        const allControls = menu.Tabs.flatMap(tab => tab.TabRegions).flatMap(region => region.Controls);
        allControls.forEach(control => {
            (control as any).Size = (control as any).Size ?? 100;
            (control as any).TooltipType = (control as any).TooltipType ?? TooltipType.Normal;
        });
    }

    private createMenu(): Menu {
        return {
            Id: 1,
            Favorites: false,
            Tabs: [
                this.menuDesign()
            ],
            Footer: {
                Controls: []
            }
        };
    }

    private menuDesign(): Tab {
        return {
            DisplayKey: 'Glass.Navigation.TabDesign',
            Id: 6,
            Name: 'design-tab',
            Image: 'tab-design',
            IconImage: getSpriteAsIconStyle('sprite-tab-design'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.menuDesignTemperature(),
                this.menuDesignGlassGeometry(),
                this.menuDesignShoeGeometry(),
                this.menuDesignWind()
            ]
        };
    }

    private menuDesignTemperature(): Region {
        return {
            DisplayKey: 'Glass.Navigation.TabDesign.RegionTemperature',
            Id: 11,
            Name: 'design-temperature-section',
            Controls: [
                {
                    CodelistName: 'temperature',
                    ControlType: 'Dropdown',
                    Name: 'design-temperature-dropdown',
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionTemperature.Temperature',
                    UIPropertyId: 'temperatureId',
                    TooltipTitleDisplayKey: 'Glass.Navigation.TabDesign.RegionTemperature.Temperature.Tooltip.Title',
                    TooltipDisplayKey: 'Glass.Navigation.TabDesign.RegionTemperature.Temperature.Tooltip.Description'
                },
            ],
            PreventFavorites: true
        };
    }

    private menuDesignGlassGeometry(): Region {
        return {
            DisplayKey: 'Glass.Navigation.TabDesign.RegionGlassGeometry',
            Id: 12,
            Name: 'design-glass-geometry-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'glass-pane-length',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionGlassGeometry.GlassPaneLength',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'glassPaneLength'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'glass-pane-height',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionGlassGeometry.GlassPanelHeight',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'glassPaneHeight'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'glass-pane-thickness',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionGlassGeometry.GlassPanelThickness',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'glassPaneThickness'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'number-of-panes',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionGlassGeometry.NumberOfPanes',
                    UnitGroup: UnitGroup.None,
                    UIPropertyId: 'numberOfPanes'
                },
            ],
            PreventFavorites: true
        };
    }

    private menuDesignShoeGeometry(): Region {
        return {
            DisplayKey: 'Glass.Navigation.TabDesign.RegionShoeGeometry',
            Id: 13,
            Name: 'design-shoe-geometry-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'shoe-height',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionShoeGeometry.ShoeHeight',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'shoeHeight'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'shoe-width',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionShoeGeometry.ShoeWidth',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'shoeWidth'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'distance-glass-shoe',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionShoeGeometry.DistanceGlassShoe',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'distanceGlassShoe'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'effective-puck-height',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionShoeGeometry.EffectivePuckHeight',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 'effectivePuckHeight'
                },
            ],
            PreventFavorites: true
        };
    }

    private menuDesignWind(): Region {
        return {
            DisplayKey: 'Glass.Navigation.TabDesign.RegionWind',
            Id: 14,
            Name: 'design-wind-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'wind-load',
                    Size: 50,
                    TitleDisplayKey: 'Glass.Navigation.TabDesign.RegionWind.WindLoad',
                    UnitGroup: UnitGroup.Stress,
                    UIPropertyId: 'windLoad'
                },
            ],
            PreventFavorites: true
        };
    }
}
