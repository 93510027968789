import { Injectable } from '@angular/core';
import { IntroJs, TourServiceInjected } from '@profis-engineering/pe-ui-common/services/tour.common';
import { IVirtualTour } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { UserSettingsService } from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class TourService extends TourServiceInjected {
    private activeRebarUnlockTour: IntroJs | null = null;

    constructor(
        private userSettingsService: UserSettingsService
    ) {
        super();
    }

    public getVirtualTours(): IVirtualTour[] {
        return [];

        // TODO FILIP: example
        // return [
        //     {
        //         name: 'TEMPTour',
        //         order: 1,
        //         buttonKey: 'Glass.VirtualTour.TEMP.Button',
        //         openTour: this.TEMPTour.bind(this),
        //         isAvailable: () => true,
        //         alreadySeen: () => this.userSettingsService.settings.glass.tourSeen.value,
        //         markAsSeen: async () => {
        //             this.userSettingsService.settings.glass.tourSeen.value = true;
        //             await this.userSettingsService.save();
        //         }
        //     }
        // ];
    }

    public TEMPTour() {
        const title = 'Agito.Hilti.Profis3.VirtualTour.OverlayWalktrough';
        const numberOfSteps = 4;
        const menuContainer = this.rootElement?.querySelector('.menu-container')?.shadowRoot ?? undefined;

        return this.start({
            highlightClass: 'no-background',
            steps: [
                {
                    element: this.rootElement?.querySelector('.main-header') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourMainPage.MessageHeader', numberOfSteps, 1),
                    position: 'bottom-middle-aligned',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: menuContainer?.querySelector('.tab-menu') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourMainPage.TabsMenu', numberOfSteps, 2),
                    position: 'right',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.loads-container-wrapper') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourMainPage.Loads', numberOfSteps, 3),
                    position: 'auto',
                    tooltipClass: 'virtual-tour-popup-modal'
                },
                {
                    element: this.rootElement?.querySelector('.main-content-right-util') as HTMLElement,
                    intro: this.introStepTemplate(title, 'Agito.Hilti.C2C.TourMainPage.Results', numberOfSteps, 4),
                    position: 'left',
                    tooltipClass: 'virtual-tour-popup-modal'
                }
            ]
        });
    }

    private get rootElement() {
        return document.documentElement.querySelector('glass-main')?.shadowRoot;
    }
}
