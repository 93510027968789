import { Injectable } from '@angular/core';
import { ModalServiceInjected } from '@profis-engineering/pe-ui-common/services/modal.common';
import { LocalizationService } from './localization.service';

@Injectable({
    providedIn: 'root'
})
export class ModalService extends ModalServiceInjected {

    constructor(
        private localizationService: LocalizationService,
    ) {
        super();
    }

    public openExportReport(props?: object) {
        this.openWebComponentModal('glass-export-report', { size: 'lg' }, props);
    }

    public async openConfirmDialog(id: string, message: string) : Promise<boolean>{
        let result = false;
        return await this.openConfirmChange({
            id: id,
            title: this.localizationService.getString('Glass.General.Warning'),
            message: message,
            confirmButtonText: this.localizationService.getString('Glass.General.OK'),
            cancelButtonText: this.localizationService.getString('Glass.General.Cancel'),
            onConfirm: (modal) => {
                modal.close();
                result = true;
            },
            onCancel: (modal) => {
                modal.close();
            }
        }).closed.then(() => result);
    }
}
