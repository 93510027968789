<div class="box-section box-section-white">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static"
            [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.DragToMove' | l10n: localizationService.selectedLanguage">
            <span class="bar-title" [innerHtml]="'Glass.DesignResults' | l10n: localizationService.selectedLanguage"></span>
        </div>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="items box-section-content">

        <div class="design-result">
            <div class="result-label" l10n="Glass.DesignResults.NumberOfPucks"></div>
            <div class="result-value">{{numberOfPucksDisplay}}&nbsp;<span class="result-unit" l10n="Glass.DesignResults.NumberOfPucks.Unit"></span></div>
        </div>

        <div class="design-result">
            <div class="result-label" l10n="Glass.DesignResults.MaxSpacingPucks"></div>
            <div class="result-value">{{maxSpacingPucksDisplay}}&nbsp;<span class="result-unit">{{unitLengthDisplay}}</span></div>
        </div>

        <div class="design-result">
            <div class="result-label" l10n="Glass.DesignResults.TotalFreeSpace"></div>
            <div class="result-value">{{totalFreeSpaceDisplay}}&nbsp;<span class="result-unit">{{unitLengthDisplay}}</span></div>
        </div>

        <div class="design-result">
            <div class="result-label" l10n="Glass.DesignResults.DistancePucks"></div>
            <div class="result-value">{{distancePucksDisplay}}&nbsp;<span class="result-unit">{{unitLengthDisplay}}</span></div>
        </div>

        <div class="design-result">
            <div class="result-label" l10n="Glass.DesignResults.Cartridges330"></div>
            <div class="result-value">{{cartridges330Display}}&nbsp;<span class="result-unit" l10n="Glass.DesignResults.Cartridges330.Unit"></span></div>
        </div>

        <div class="design-result">
            <div class="result-label" l10n="Glass.DesignResults.Cartridges500"></div>
            <div class="result-value">{{cartridges500Display}}&nbsp;<span class="result-unit" l10n="Glass.DesignResults.Cartridges500.Unit"></span></div>
        </div>

    </div>
</div>
