import { Injectable } from '@angular/core';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeListService } from './common-code-list.service';
import { DataService } from './data.service';
import { UserSettingsService } from './user-settings.service';

export interface ApplicationSettings {
    regionId: KnownRegion | SpecialRegion;
    length: UnitType;
    stress: UnitType;
    force: UnitType;
    moment: UnitType;
}

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    constructor(
        private userSettingsService: UserSettingsService,
        private commonCodeListService: CommonCodeListService,
        private dataService: DataService
    ) { }

    public loadSettings(): ApplicationSettings {
        const quickStart = this.userSettingsService.settings.glass.quickStart.main;

        return {
            regionId: quickStart.regionId.value,
            length: quickStart.length.value,
            stress: quickStart.stress.value,
            force: quickStart.force.value,
            moment: quickStart.moment.value,
        };
    }

    public handleRegionChange(applicationSettings: ApplicationSettings, regionId: number): void {
        // ignore regions that we don't support
        // when Default is selected with unsupported region we treat is the same as None
        if (regionId in this.dataService.regionsById) {
            applicationSettings.length = this.dataService.getPropertyDetail(regionId, 'unitLength').defaultValue;
            applicationSettings.stress = this.dataService.getPropertyDetail(regionId, 'unitStress').defaultValue;
            applicationSettings.force = this.dataService.getPropertyDetail(regionId, 'unitForce').defaultValue;
            applicationSettings.moment = this.dataService.getPropertyDetail(regionId, 'unitMoment').defaultValue;
        }
    }

    public updateSettings(applicationSettings: ApplicationSettings): void {
        if (applicationSettings == null) {
            return;
        }

        const quickStart = this.userSettingsService.settings.glass.quickStart.main;

        quickStart.regionId.value = applicationSettings.regionId;
        quickStart.length.value = applicationSettings.length;
        quickStart.stress.value = applicationSettings.stress;
        quickStart.force.value = applicationSettings.force;
        quickStart.moment.value = applicationSettings.moment;
    }
}
