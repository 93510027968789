<div class="design-section box-section box-section-white">
    <div class="box-section-content">
        <div class="design-section-row">
            <button id="export-button" type="button" class="generate-report-button button button-primary"
                (click)="generateReport()"
                [disabled]="disabled || loading || !isCalculationValid">
                <pe-loading-button
                    [loading]="loading"
                    [color]="'#ffffff'">
                    <div class="generate-report-button-container">
                        <span class="text" l10n="Agito.Hilti.Profis3.Main.GenerateReport"></span>
                        <span class="pe-ui-glass-sprite pe-ui-glass-sprite-long-arrow-right-white"></span>
                    </div>
                </pe-loading-button>
            </button>
        </div>

        <div class="design-section-row">
            <button id="export-button" type="button" class="export-button button button-design-section"
                (click)="openDesignSectionExport()"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.ExportReport' | l10n: localizationService.selectedLanguage" placement="top"
                [disabled]="disabled || loading">
                <div class="button-sprite-wrapper">
                    <span class="pe-ui-glass-sprite pe-ui-glass-sprite-export-design"></span>
                </div>
            </button>

            <button id="duplicate-button" type="button" class="duplicate-button button button-design-section"
                (click)="duplicateDesign()"
                [disabled]="duplicating"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.DuplicateDesign' | l10n: localizationService.selectedLanguage" placement="top"
                [disabled]="disabled || loading">
                <div class="button-sprite-wrapper">
                    <pe-loading-button
                        [loading]="duplicating">
                        <span class="pe-ui-glass-sprite pe-ui-glass-sprite-duplicate-design"></span>
                    </pe-loading-button>
                </div>
            </button>

            <button id="open-button" type="button" class="open-button button button-design-section"
                (click)="selectOpenFile()"
                [disabled]="openDesign"
                [ngbTooltip]="'Agito.Hilti.Profis3.Main.OpenDesign' | l10n: localizationService.selectedLanguage" placement="top"
                [disabled]="disabled || loading">
                <div class="button-sprite-wrapper">
                    <pe-loading-button
                        [loading]="openDesign">
                        <span class="pe-ui-glass-sprite pe-ui-glass-sprite-openfile-d-light"></span>
                    </pe-loading-button>
                </div>
            </button>

            <input class="open-design-input" #openDesignInput (change)="importFileSelected()" name="project" type="file"
                accept=".pe" (click)="$event.stopPropagation();" />
        </div>
    </div>
</div>
