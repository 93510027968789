import { Injectable } from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { MarkRequired } from 'ts-essentials';
import { PickKeysByValue } from 'ts-essentials/dist/pick-keys-by-value';
import { CommonCodeListService } from './common-code-list.service';
import { Properties } from './design.service';
import { CoreApiService } from './core-api.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { environment } from '../../environments/environment';

export interface AppData {
    propertyDetails: ApiAppPropertyDetails;
    regions: { id: number }[];

    temperatures: Temperature[];
}

export type AppPropertyId = keyof Pick<Properties,
    'unitLength' |
    'unitStress' |
    'unitForce' |
    'unitMoment' |

    'temperatureId' |
    'glassPaneLength' |
    'glassPaneHeight' |
    'glassPaneThickness' |
    'numberOfPanes' |
    'shoeHeight' |
    'shoeWidth' |
    'distanceGlassShoe' |
    'effectivePuckHeight' |
    'windLoad'
>;

export type PropertyDetailDefault = MarkRequired<PropertyDetail, 'defaultValue'>;
export type PropertyDetailDefaultMinMax = MarkRequired<PropertyDetail, 'defaultValue' | 'minValue' | 'maxValue'>;
export type PropertyDetailDefaultMinMaxPrecision = MarkRequired<PropertyDetail, 'defaultValue' | 'minValue' | 'maxValue' | 'precision'>;

/** property detail per property - some properties like units always have defaultValue so we remove the optional marker */
export type AppPropertyDetailMap = Pick<{
    'unitLength': PropertyDetailDefault;
    'unitStress': PropertyDetailDefault;
    'unitForce': PropertyDetailDefault;
    'unitMoment': PropertyDetailDefault;

    'temperatureId': PropertyDetailDefault;
    'glassPaneLength': PropertyDetailDefaultMinMax;
    'glassPaneHeight': PropertyDetailDefaultMinMax;
    'glassPaneThickness': PropertyDetailDefaultMinMax;
    'numberOfPanes': PropertyDetailDefaultMinMax;
    'shoeHeight': PropertyDetailDefaultMinMax;
    'shoeWidth': PropertyDetailDefaultMinMax;
    'distanceGlassShoe': PropertyDetailDefaultMinMax;
    'effectivePuckHeight': PropertyDetailDefaultMinMax;
    'windLoad': PropertyDetailDefaultMinMaxPrecision;
},
AppPropertyId>;

export type ApiAppPropertyId = keyof Pick<AppPropertyDetailMap,
    'temperatureId' |
    'glassPaneLength' |
    'glassPaneHeight' |
    'glassPaneThickness' |
    'numberOfPanes' |
    'shoeHeight' |
    'shoeWidth' |
    'distanceGlassShoe' |
    'effectivePuckHeight' |
    'windLoad'
>;
export type ApiAppPropertyDetails = Record<ApiAppPropertyId, ApiPropertyDetailGroup[]>;
export interface ApiPropertyDetailGroup {
    regionId: number;
    propertyDetail: PropertyDetail;
}

export interface PropertyDetail {
    defaultValue?: number;
    minValue?: number;
    maxValue?: number;
    precision?: number;
    allowedValues?: number[];
    disabled?: boolean;
    hidden?: boolean;
}

export interface Region {
    id: number;
    nameKey: string;
}

export interface Temperature {
    id: number;
    nameKey?: string;
}

export interface Unit {
    id: number;
    name: string;
}

type PropertyDetails = Record<number, Record<AppPropertyId, PropertyDetail>>;

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public regions!: Region[];
    public regionsById!: Record<number, Region>;

    public allRegions!: Region[];
    public allRegionsById!: Record<number, Region>;

    public units!: {
        length: Unit[];
        lengthById: Record<number, Unit>;
        stress: Unit[];
        stressById: Record<number, Unit>;
        force: Unit[];
        forceById: Record<number, Unit>;
        moment: Unit[];
        momentById: Record<number, Unit>;
    };

    public appData!: AppData;
    private propertyDetails!: PropertyDetails;

    constructor(
        private commonCodeListService: CommonCodeListService,
        private coreApiService: CoreApiService,
        private featureVisibilityService: FeatureVisibilityService
    ) { }

    public async loadData() {
        this.appData = await this.coreApiService.api.app.data();

        this.initRegions();
        this.initUnits();

        this.initProperties();
    }

    /**
     * Get PropertyDetail for the specified regionId and propertyId.
     * If no PropertyDetail is found for the specified regionId we return default PropertyDetail that are not connected with a region (regionId == 0).
     */
    public getPropertyDetail<K extends keyof AppPropertyDetailMap>(regionId: number, propertyId: K): AppPropertyDetailMap[K] {
        // for now we only filter by regionId
        // if in the future we add other filters (design standard?) we have to update this code
        const propertyDetail = this.propertyDetails[regionId]?.[propertyId];

        // try with default region
        if (propertyDetail == null && regionId != 0) {
            return this.getPropertyDetail(0, propertyId);
        }

        return propertyDetail as AppPropertyDetailMap[K];
    }

    private initProperties() {
        this.propertyDetails = {};

        for (const _propertyId in this.appData.propertyDetails) {
            const propertyId = _propertyId as ApiAppPropertyId;
            const propertyDetailGroups = this.appData.propertyDetails[propertyId];

            for (const propertyDetailGroup of propertyDetailGroups) {
                (this.propertyDetails[propertyDetailGroup.regionId] = this.propertyDetails[propertyDetailGroup.regionId] ?? {})[propertyId] = propertyDetailGroup.propertyDetail;
            }
        }

        // add units as properties from common code list
        const commonRegions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];

        for (const commonRegion of commonRegions) {
            const region = this.regionsById[commonRegion.id];

            if (region != null) {
                addUnitPropertyDetail(this.propertyDetails, commonRegion, 'unitLength', 'defaultUnitLength');
                addUnitPropertyDetail(this.propertyDetails, commonRegion, 'unitStress', 'defaultUnitStress');
                addUnitPropertyDetail(this.propertyDetails, commonRegion, 'unitForce', 'defaultUnitForce');
                addUnitPropertyDetail(this.propertyDetails, commonRegion, 'unitMoment', 'defaultUnitMoment');
            }
        }

        function addUnitPropertyDetail<T extends PickKeysByValue<CommonRegion, number | null | undefined>>(propertyDetails: PropertyDetails, commonRegion: CommonRegion, propertyId: AppPropertyId, defaultProperty: T) {
            (propertyDetails[commonRegion.id] = propertyDetails[commonRegion.id] ?? {})[propertyId] = {
                defaultValue: (commonRegion[defaultProperty as keyof CommonRegion] as number | null | undefined) ?? undefined
            };
        }
    }

    private toDictionary<T, P extends keyof T>(values: T[], property: P) {
        return values.reduce((valuesByProperty, value) => { valuesByProperty[value[property]] = value; return valuesByProperty; }, {} as any) as Record<T[P] extends string | number | symbol ? T[P] : never, T>;
    }

    private groupById<T, P extends PickKeysByValue<T, number[]>>(values: T[], property: P, ids: number[]) {
        const valuesById: Record<number, T[]> = {};

        for (const id of ids) {
            valuesById[id] = [];
        }

        for (const value of values) {
            for (const id of value[property] as number[]) {
                valuesById[id].push(value);
            }
        }

        return valuesById;
    }

    private initRegions(): void {
        const configRegionIds = environment.featuresConfig?.find(x => x.key == 'Regions')?.value ?? [];
        const allowedRegionIds = this.featureVisibilityService.getFeatureValue<number[]>('Glass_Regions', configRegionIds);

        const commonRegions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];

        // all regions
        this.allRegions = commonRegions
            .map((x): Region => ({
                id: x.id,
                // take the translation key from pe-ui
                nameKey: x.nameResourceKey as string
            }));
        this.allRegionsById = this.toDictionary(this.allRegions, 'id');

        // glass regions
        this.regions = this.allRegions.filter(x => allowedRegionIds.includes(x.id));
        this.regionsById = this.toDictionary(this.regions, 'id');

        // code list regions
        this.appData.regions = this.regions.map(x => ({ id: x.id }));
    }

    private initUnits(): void {
        this.units = {
            length: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitLength] as Unit[],
            lengthById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitLength] as Unit[], 'id'),

            stress: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitStress] as Unit[],
            stressById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitStress] as Unit[], 'id'),

            force: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitForce] as Unit[],
            forceById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitForce] as Unit[], 'id'),

            moment: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitMoment] as Unit[],
            momentById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitMoment] as Unit[], 'id')
        };
    }
}
