import { APP_BASE_HREF } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from 'ngx-sortablejs';
import { L10nDirective } from './directives/l10n.directive';
import { CloseOutsideNgbDropdownDirective } from './directives/close-outside-ngb-dropdown.directive';
import { L10nPipe } from './pipes/l10n.pipe';
import { UiInitComponent } from './components/ui-init/ui-init.component';
import { AddEditDesignComponent } from './components/add-edit-design/add-edit-design.component';
import { AppSettingsComponent } from './components/app-settings/app-settings.component';
import { MainComponent } from './components/main/main.component';
import { DesignSectionComponent } from './components/design-section/design-section.component';
import { ExportReportComponent } from './components/export-report/export-report.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { DesignResultsComponent } from './components/design-results/design-results.component';

@NgModule({
    declarations: [
        L10nDirective,
        CloseOutsideNgbDropdownDirective,
        L10nPipe,
        UiInitComponent,
        AddEditDesignComponent,
        AppSettingsComponent,
        MainComponent,
        DesignSectionComponent,
        ExportReportComponent,
        NotificationsComponent,
        DesignResultsComponent
    ],
    imports: [
        BrowserModule,
        NgbTooltipModule,
        NgbDropdownModule,
        FormsModule,
        VirtualScrollerModule,
        SortablejsModule.forRoot({})
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: '/cdn/pe-ui-glass/' }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private injector: Injector,
        ngbTooltipConfig: NgbTooltipConfig
    ) {
        this.configureTooltip(ngbTooltipConfig);

        this.registerCustomElement('ui-init', UiInitComponent);
        this.registerCustomElement('add-edit-design', AddEditDesignComponent);
        this.registerCustomElement('app-settings', AppSettingsComponent);
        this.registerCustomElement('main', MainComponent);
        this.registerCustomElement('export-report', ExportReportComponent);
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @angular-eslint/use-lifecycle-interface, @typescript-eslint/no-empty-function
    public ngDoBootstrap() {
        // Nothing to do.
    }

    private configureTooltip(config: NgbTooltipConfig) {
        config.container = 'body';
        config.triggers = 'hover';
        config.openDelay = 500;
    }

    private registerCustomElement(componentName: string, component: Type<any>) {
        const customElement = createCustomElement(component, { injector: this.injector });
        customElements.define(`glass-${componentName}`, customElement);
    }
}

