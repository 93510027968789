import { Injectable } from '@angular/core';
import { DesignType } from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    AppSettings, IDesignInfo, IDesignListInfo, IFavoritesInfo, IImportDesignProvider,
    IQuickStartApplication} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { getModuleOrder } from '@profis-engineering/pe-ui-common/helpers/module-helper';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';

import { getSpriteAsIconStyle } from '../sprites';
import { ApplicationSettings, AppSettingsService } from './app-settings.service';
import { CommonCodeListService } from './common-code-list.service';
import { DataService } from './data.service';
import {
    DesignDetails, DesignService, DesignTypeId, designTypes, designTypesById
} from './design.service';
import { DocumentService } from './document.service';
import { FavoritesService } from './favorites.service';
import { ImportDesignProviderService } from './import-design-provider.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { RoutingService } from './routing.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { TrackingDetails, TrackingService } from './tracking.service';

const moduleIndex = 3;

@Injectable({
    providedIn: 'root'
})
export class ApplicationProviderService {

    constructor(
        private localizationService: LocalizationService,
        private commonCodeListService: CommonCodeListService,
        private userSettingsService: UserSettingsService,
        private appSettingsService: AppSettingsService,
        private designService: DesignService,
        private modalService: ModalService,
        private favoritesService: FavoritesService,
        private documentService: DocumentService,
        private dataService: DataService,
        private userService: UserService,
        private routingService: RoutingService,
        private importDesignProvider: ImportDesignProviderService,
        private featuresVisibilityService: FeatureVisibilityService,
        private trackingService: TrackingService
    ) {}

    /** gets called when a language changes */
    public updateTranslations() {
        this.quickStartApplications!.designTypeTranslation = this.localizationService.getString('Glass.QuickStartButtons.DesignType');
        this.appSettings!.title = this.localizationService.getString('Glass.QuickStartSettings');
        this.appSettings!.sections[0].title = this.localizationService.getString('Glass.QuickStartSettings.Section');
        this.designInfo!.title = this.localizationService.getString('Glass.AddEditDesign');
        this.designListInfo!.designTypeName = this.localizationService.getString('Glass.DesignList.DesignType');

        for (const designType of this.designTypes ?? []) {
            designType.name = this.localizationService.getString(designTypesById[designType.id as DesignTypeId].nameKey);
        }
    }

    /** design list section name */
    private designTypes?: DesignType[];
    public getDesignTypes(): DesignType[] {
        this.designTypes ??= Object.values(designTypes).map(designType => new DesignType({
            id: designType.id,
            name: this.localizationService.getString(designType.nameKey)
            // TODO FILIP: pe-ui still calls this when there is no pe-ui-pe module
            // allowedDesignStandardRegions
        }));

        return this.designTypes;
    }

    /** quick start settings */
    private appSettings?: AppSettings;
    public getAppSettings(): AppSettings[] {
        this.appSettings ??= {
            order: getModuleOrder(moduleIndex, 0),
            name: 'app-settings-glass-module',
            collapsed: false,
            titleKey: undefined!,
            title: this.localizationService.getString('Glass.QuickStartSettings'),
            tooltipKey: 'Common.ShowHideSection.Tooltip',
            sections: [
                {
                    id: 'application-settings-glass',
                    titleKey: undefined!,
                    title: this.localizationService.getString('Glass.QuickStartSettings.Section'),
                    type: designTypes.main.id,
                    componentTagName: 'glass-app-settings',

                    loadSettings: () => this.appSettingsService.loadSettings(),
                    handleRegionChange: (data, regionId) => this.appSettingsService.handleRegionChange(data as ApplicationSettings, regionId),
                    updateSettings: (data) => this.appSettingsService.updateSettings(data as ApplicationSettings)
                }
            ]
        };

        return [
            this.appSettings
        ];
    }

    /** quick start buttons */
    private quickStartApplications?: IQuickStartApplication;
    public getQuickStartApplications(): IQuickStartApplication[] {
        const isNewHomePageEnabled = this.featuresVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');

        this.quickStartApplications ??= {
            order: getModuleOrder(moduleIndex, 0),
            showInQuickStart: true,
            showInCollapsedState: false,

            designType: designTypes.main.id,

            idQuickStart: 'quick-start-button-glass-main',
            imageStyle: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-module-mid') : getSpriteAsIconStyle('sprite-module'),
            // TODO new home page: rename sprite-module-mid to sprite-module and remove switch when new home page is deployed
            // actionTranslationKey is of no use in new home page - remove it when new home page deployment
            actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.QuickStart.Concrete.Action',
            designTypeTranslation: this.localizationService.getString('Glass.QuickStartButtons.DesignType'),
            newLabelTranslationKey: 'Common.QuickStartButtons.NewStatus',

            isEnabled: () => this.dataService.regionsById[this.userSettingsService.settings.glass.quickStart.main.resolvedRegionId ?? 0] != null,
            isDesignTypeDisabled: () => false,
            getButtonTooltip: () => undefined,
            createRegionDesignStandardApprovalNumber: () => this.designService.createRegionDesignStandardApprovalNumber(this.userSettingsService.settings.glass.quickStart.main.resolvedRegionId ?? 0),
            showNewLabel: () => true,
            getNewDesignName: () => this.designService.getNewDesignName(),
            createNewDesign: saveDesign => new Promise(resolve => setTimeout(() => resolve(this.createNewQuickStartDesign(saveDesign))))
        };

        return [
            this.quickStartApplications
        ];
    }

    /** design list */
    private designListInfo?: IDesignListInfo;
    public getDesignListInfo(): IDesignListInfo[] {
        const isNewHomePageEnabled = this.featuresVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');

        this.designListInfo ??= {
            order: getModuleOrder(moduleIndex, 0),
            designTypeId: designTypes.main.id,
            designTypeName: this.localizationService.getString('Glass.DesignList.DesignType'),
            designTypeImage: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-model3d-mid') : getSpriteAsIconStyle('sprite-model3d'),
            // TODO new home page: rename sprite-model3d-mid to sprite-model3d and remove switch when new home page is deployed

            isEnabled: () => true,
            isBulkReportEnabled: () => false,
            checkDesign: (design: IBaseDesign) => this.checkDesign(design),

            openFromDocumentDesign: (documentDesign: IDesignListItem) => this.openFromDocumentDesign(documentDesign),
            openDesignSettings: (designId: string, _designName: string, _regionId: number, onDesignSettingsClosed?: () => void) => this.openDesignSettings(designId, onDesignSettingsClosed),
            openTemplate: (templateId: string) => this.openTemplate(templateId),
            openTemplateSettings: (templateId: string, onTemplateSettingsClosed?: () => void) => this.openTemplateSettings(templateId, onTemplateSettingsClosed),
            newDesignFromTemplate: (templateId, projectId, designName) => this.newDesignFromTemplate(templateId, projectId, designName),
            getDesign: (designId: string) => this.getDesign(designId),
            toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design,  isNewHomePageEnabled ? 'sprite-model3d-mid' : 'sprite-model3d', getDesignThumbnail),
            // TODO new home page: rename sprite-model3d-mid to sprite-model3d and remove switch when new home page is deployed
            toDisplayDesignTemplate: (template: DesignTemplateEntity, getDesignThumbnail?: (templateId: string) => string) => this.designService.toDisplayDesignTemplate(template, isNewHomePageEnabled ? 'sprite-model3d-mid' : 'sprite-model3d', getDesignThumbnail),
            // TODO new home page: rename sprite-model3d-mid to sprite-model3d and remove switch when new home page is deployed
            getProjectDesignFromDesign: (design: Design) => (design as any).designDetails.projectDesign
        };

        return [
            this.designListInfo
        ];
    }

    /** add edit design */
    private designInfo?: IDesignInfo;
    public getDesignInfo(designName?: string): IDesignInfo[] {
        this.designInfo ??= {
            order: getModuleOrder(moduleIndex, 0),
            id: 'add-edit-design-glass-main',
            designTypeId: designTypes.main.id,
            titleTranslationKey: undefined!,
            title: this.localizationService.getString('Glass.AddEditDesign'),
            mainTagName: 'glass-main',
            componentTagName: 'glass-add-edit-design',
            imageStyle: () => getSpriteAsIconStyle('sprite-module-custom'),
            availableRegions: this.getAvailableRegionIds(),
            isEnabled: () => true,
            isAvailable: (regionId) => this.dataService.regionsById[regionId] != null,
            getDesignName: () => designName ?? this.designService.getNewDesignName()
        };

        return [
            this.designInfo
        ];
    }

    /** favorites id */
    public getFavoritesInfo(): IFavoritesInfo {
        return {
            getFavoritesType: (menuType, designType) => this.favoritesService.getFavoritesType(menuType, designType),
            getMenuRegionIdFromFavorites: (id, designType) => this.favoritesService.getMenuRegionIdFavorites(id, designType)
        };
    }

    /** project page => design import */
    public getImportDesignProvider(): IImportDesignProvider {
        return this.importDesignProvider;
    }

    /** called when design is opened from url */
    private async checkDesign(design: IBaseDesign): Promise<ICalculationResult> {
        // closeDesign will be called by main.component
        const { designDetails, trackingDetails } = await this.designService.openDesign({
            designId: design.id
        });

        const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

        return {
            design: peDesignObject
        } as ICalculationResult;
    }

    /** design list for templates => template settings */
    private async openTemplateSettings(templateId: string, onClosed?: () => void) {
        let designDetails: DesignDetails = undefined!;
        let trackingDetails: TrackingDetails = undefined!;

        try {
            ({ designDetails, trackingDetails } = await this.designService.openDesignTemplate({
                designTemplateId: templateId
            }));

            const designInfo = this.getDesignInfo()
                .find(x => x.designTypeId == designDetails!.designTypeId && x.isAvailable(designDetails!.regionId));

            if (designInfo == undefined) {
                throw new Error('Design info not found');
            }

            await this.modalService.openAddEditDesignFromModule({
                design: {
                    id: designDetails.templateId,
                    name: designDetails.templateName,
                    region: designDetails.commonRegion,
                    designType: designDetails.designTypeId,
                    displayDesignType: DisplayDesignType.template,
                    designTemplateDocumentId: designDetails.templateId,
                    // TODO FILIP: do we need anchorName and approvalNumber?
                    anchorName: '',
                    approvalNumber: '',
                    projectDesign: designDetails.projectDesign,
                    design: this.designService.createPeDesignObject(designDetails, trackingDetails)
                },
                addEditType: AddEditType.edit,
                selectedModuleDesignInfo: designInfo
            }).closed;
        }
        finally {
            if (designDetails != null && trackingDetails != null) {
                // no need to await
                this.designService.closeDesignTemplate(designDetails, trackingDetails)
                    .catch(error => console.error(error));
            }

            onClosed?.();
        }
    }

    /** design list => open */
    private async openFromDocumentDesign(documentDesign: IDesignListItem): Promise<ICalculationResult> {
        // TODO FILIP: do we need this?
        documentDesign.projectName = this.documentService.findProjectById(documentDesign.projectId).name ?? '';

        // closeDesign will be called by main.component
        const { designDetails, trackingDetails } = await this.designService.openDesign({
            designId: documentDesign.id
        });

        const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

        this.userService.changeDesign(this.documentService.findProjectById(designDetails.projectId!), peDesignObject);
        // TODO TEAM: can we have a normal await?
        this.routingService.navigateToUrl(UrlPath.main + designDetails.designId)
            .catch(error => console.error(error));

        // not used in pe-ui
        return undefined as unknown as ICalculationResult;
    }

    /** design list => edit template */
    private async openTemplate(templateId: string): Promise<ISaveDesignResult> {
        try {
            const { designDetails, trackingDetails } = await this.designService.openDesignTemplate({
                designTemplateId: templateId
            });

            const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

            this.userService.changeDesign(undefined, peDesignObject);

            // TODO TEAM: can we have a normal await?
            this.routingService.navigateToUrl(UrlPath.main + designDetails.templateId!)
                .catch(error => console.error(error));

            return {
                designId: designDetails.templateId!,
                path: UrlPath.main + designDetails.templateId!,
                design: peDesignObject,
                success: true
            };
        }
        catch (error) {
            console.error(error);

            return {
                path: UrlPath.main,
                success: false,
                designId: undefined as unknown as string,
                design: undefined as unknown as Design
            };
        }
    }

    /** design list => create design file or box click */
    public async newDesignFromTemplate(templateId: string, projectId?: string, designName?: string): Promise<ISaveDesignResult> {
        try {
            const { designDetails, trackingDetails } = await this.designService.createDesignFromTemplate({
                templateId,
                projectId,
                designName
            });

            const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

            this.userService.changeDesign(this.documentService.draftsProject, peDesignObject);
            // TODO TEAM: can we have a normal await?
            this.routingService.navigateToUrl(UrlPath.main + designDetails.designId!)
                .catch(error => console.error(error));

            return {
                designId: designDetails.designId!,
                path: UrlPath.main + designDetails.designId!,
                design: peDesignObject,
                success: true
            };

        }
        catch (error) {
            console.error(error);

            return {
                path: UrlPath.main,
                success: false,
                designId: undefined as unknown as string,
                design: undefined as unknown as Design
            };
        }
    }

    /** design list => design settings */
    private async openDesignSettings(designId: string, onClosed?: () => void): Promise<void> {
        let designDetails: DesignDetails | null = null;
        let trackingDetails: TrackingDetails = undefined!;

        try {
            // closeDesign is called in finally
            ({ designDetails, trackingDetails } = await this.designService.openDesign({
                designId
            }));

            const designInfo = this.getDesignInfo()
                .find(x => x.designTypeId == designDetails!.designTypeId && x.isAvailable(designDetails!.regionId));

            if (designInfo == undefined) {
                throw new Error('Design info not found');
            }

            await this.modalService.openAddEditDesignFromModule({
                design: {
                    id: designDetails.designId!,
                    name: designDetails.designName,
                    projectId: designDetails.projectId!,
                    projectName: designDetails.projectName,
                    region: designDetails.commonRegion,
                    designType: designDetails.designTypeId,
                    displayDesignType: DisplayDesignType.design,
                    // TODO FILIP: do we need anchorName and approvalNumber?
                    anchorName: '',
                    approvalNumber: '',
                    projectDesign: designDetails.projectDesign,
                    design: this.designService.createPeDesignObject(designDetails, trackingDetails)
                },
                addEditType: AddEditType.edit,
                selectedModuleDesignInfo: designInfo
            }).closed;
        }
        finally {
            if (designDetails != null) {
                // no need to await
                this.designService.closeDesign(designDetails, trackingDetails)
                    .catch(error => console.error(error));
            }

            onClosed?.();
        }
    }

    /** design list => drop on project => design name conflict */
    private async getDesign(designId: string): Promise<Design> {
        // only called by rename popup from pe-ui

        // pe-ui will call design.processDesignClose(false) when done renaming
        const { designDetails, trackingDetails } = await this.designService.openDesign({
            designId
        });

        const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);
        (peDesignObject as unknown as { processDesignClose: () => void }).processDesignClose = () => {
            // document service close is already called by pe-ui
            this.trackingService.trackOnDesignClose(designDetails, trackingDetails)
                .catch(error => console.error(error));
        };

        return peDesignObject;
    }

    /** Allowed region ids */
    private getAvailableRegionIds(): number[] {
        return this.dataService.regions.map(x => x.id);
    }

    /** Quick start new design */
    private async createNewQuickStartDesign(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        try {
            const quickStart = this.userSettingsService.settings.glass.quickStart.main;

            const { designDetails, trackingDetails } = await this.designService.createDesign({
                designName: saveDesign.designName,
                designTypeId: designTypes.main.id,
                projectId: saveDesign.projectId,
                regionId: quickStart.resolvedRegionId!,

                unitLength: quickStart.length.value,
                unitStress: quickStart.stress.value,
                unitForce: quickStart.force.value,
                unitMoment: quickStart.moment.value
            });

            const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

            return {
                designId: designDetails.designId!,
                path: UrlPath.main,
                design: peDesignObject,
                success: true
            };
        }
        catch (error) {
            // pe-ui will not log any errors
            console.error(error);

            throw error;
        }
    }
}
