import { Component, Input, OnChanges } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CollapsingControls } from '../../collapsing-controls';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    selector: 'app-design-results',
    templateUrl: './design-results.component.html',
    styleUrls: ['./design-results.component.scss']
})
export class DesignResultsComponent implements OnChanges {

    @Input()
    public numberOfPucks?: number;

    @Input()
    public maxSpacingPucks?: number;

    @Input()
    public totalFreeSpace?: number;

    @Input()
    public distancePucks?: number;

    @Input()
    public cartridges330?: number;

    @Input()
    public cartridges500?: number;

    public collapsed = false;

    constructor(
        public localizationService: LocalizationService,
        private userSettingsService: UserSettingsService,
        private unitService: UnitService
    ) {}

    // display unit might change so it's easier to have a getter instead of using events to know when to update the value
    public get unitLengthDisplay(): string {
        return this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Length));
    }

    public get numberOfPucksDisplay(): string {
        return this.formatNumber(this.numberOfPucks);
    }

    public get maxSpacingPucksDisplay(): string {
        return this.formatLength(this.maxSpacingPucks);
    }

    public get totalFreeSpaceDisplay(): string {
        return this.formatLength(this.totalFreeSpace);
    }

    public get distancePucksDisplay(): string {
        return this.formatLength(this.distancePucks);
    }

    public get cartridges330Display(): string {
        return this.formatNumber(this.cartridges330);
    }

    public get cartridges500Display(): string {
        return this.formatNumber(this.cartridges500);
    }

    public ngOnChanges(): void {
        this.collapsed = this.userSettingsService.isSectionCollapsed(CollapsingControls.DesignResults);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettingsService.setSectionCollapsed(CollapsingControls.DesignResults, collapsed);
    }

    private formatLength(internalUnit: number | undefined): string {
        return internalUnit != null
            ? this.unitService.formatUnitValueArgs(
                this.unitService.convertInternalValueToDefaultValue(internalUnit, UnitGroup.Length),
                this.unitService.getDefaultUnit(UnitGroup.Length),
                undefined,
                undefined,
                undefined,
                undefined,
                false
            ) : 'NaN';
    }

    private formatNumber(value: number | undefined): string {
        return value != null
            ? value.toString()
            : 'NaN';
    }
}
