import { Injectable } from '@angular/core';
import { ReportTemplateServiceBase, ReportTemplateServiceInjected } from '@profis-engineering/pe-ui-common/services/report-template.common';

interface IReportTemplateLogo {
    blob: Blob;
    url: string;
    base64: string;
}

interface BaseService extends ReportTemplateServiceBase {
    getLogo: (id: number, logos: Record<number, any>) => Promise<IReportTemplateLogo>;
}

@Injectable({
    providedIn: 'root'
})
export class ReportTemplateService extends ReportTemplateServiceInjected {
    public async getLogo(id: number): Promise<IReportTemplateLogo> {
        return await (this.baseService as BaseService).getLogo(id, {});
    }
}
