<div *ngIf="isLoaded">
    <div class="section no-margin">
        <div class="section-row">
            <span class="local-steel-material-disclaimer all"
                l10n="Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer"></span>
        </div>
    </div>

    <div class="section units" [class.no-margin]="collapseRegion.UnitsAndParams">
        <p class="title-strike title" [class.no-margin]="collapseRegion.UnitsAndParams">
            <span l10n="Glass.AddEditDesign.Units"></span>

            <pe-collapse class="section-collapse" [id]="'section-units-params'" [container]="'section-units-params'"
                [collapsed]="collapseRegion.UnitsAndParams"
                (collapsedChange)="collapseRegion.UnitsAndParams = $any($event).detail"></pe-collapse>
        </p>

        <div class="control-row section-units-params">
            <div class="control-column length-dropdown">
                <pe-dropdown [id]="lengthDropdown.id" [title]="lengthDropdown.title" [items]="lengthDropdown.items"
                    [selectedValue]="lengthDropdown.selectedValue"
                    (selectedValueChange)="lengthDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted"></pe-dropdown>
            </div>

            <div class="control-column stress-dropdown">
                <pe-dropdown [id]="stressDropdown.id" [title]="stressDropdown.title" [items]="stressDropdown.items"
                    [selectedValue]="stressDropdown.selectedValue"
                    (selectedValueChange)="stressDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column force-dropdown">
                <pe-dropdown [id]="forceDropdown.id" [title]="forceDropdown.title" [items]="forceDropdown.items"
                    [selectedValue]="forceDropdown.selectedValue"
                    (selectedValueChange)="forceDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column moment-dropdown">
                <pe-dropdown [id]="momentDropdown.id" [title]="momentDropdown.title" [items]="momentDropdown.items"
                    [selectedValue]="momentDropdown.selectedValue"
                    (selectedValueChange)="momentDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>
        </div>
    </div>
</div>
