import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    TrackOnOpenRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';
import { UserDetails } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Tracking';
import { trySendingUsingFetch } from '@profis-engineering/pe-ui-common/helpers/browser';
import {
    getJSONDateWithTimezoneOffset
} from '@profis-engineering/pe-ui-common/helpers/date-time-helper';

import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { CommonTrackingService } from './common-tracking.service';
import { DesignDetails, DesignTypeId } from './design.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { LocalizationService } from './localization.service';
import { UserService } from './user.service';

interface TrackingData {
    designTypeId: DesignTypeId;
    designId: string | undefined;
    templateId: string | undefined;
    activities: TrackingActivities;
}

type TrackingActivities = Record<string, string | undefined>;

export type OpenType =
    /** new design (normal or trimble connect) */
    'Blank' |
    /** open design */
    'OpenExisting' |
    /** import design */
    'ImportedProfis3File' |
    /** new design from template */
    'BlankFromTemplate' |
    /** new or open template */
    'TemplateEdit';

export interface TrackingDetails {
    openTime: number;
    openType: OpenType;
    counters: TrackingCounters;
}

export interface TrackingCounters {
    approvalViewed: number;
    reportCreated: number;
    reportCreatedWithDefaultTemplate: number;
    reportCreatedWithUserTemplate: number;
    reportCreatedWithCustomTemplate: number;
    designUndo: number;
    designRedo: number;
    designExport: number;
    designDuplicate: number;
    designImport: number;
    headerMenuOpened: number;
    headerOnlineTechnicalInformation: number;
    specificationTextExport: number;
}

export type TrackingCounterId = keyof TrackingCounters;

const counterNames: Readonly<Record<TrackingCounterId, string>> = {
    approvalViewed: 'Approval',
    reportCreated: 'ReportCreated',
    reportCreatedWithDefaultTemplate: 'ReportCreatedWithDETemplate',
    reportCreatedWithUserTemplate: 'ReportCreatedWithUSTemplate',
    reportCreatedWithCustomTemplate: 'ReportCreatedWithCUTemplate',
    designUndo: 'Undo',
    designRedo: 'Redo',
    designExport: 'DesignExportedToDevice',
    designDuplicate: 'DuplicateDesign',
    designImport: 'ImportExistingDesign',
    headerMenuOpened: 'MenuOpened',
    headerOnlineTechnicalInformation: 'OnlineTechnicalInformation',
    specificationTextExport: 'SpecificationTextExport'
};

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(
        private apiService: ApiService,
        private commonTrackingService: CommonTrackingService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private localizationService: LocalizationService,
        private userService: UserService
    ) { }

    public async trackOnTemplateOpen(designDetails: DesignDetails, openType: OpenType): Promise<TrackingDetails> {
        const trackingDetails = this.createTrackingDetails(openType);

        if (!environment.trackingEnabled) {
            return trackingDetails;
        }

        await this.sendTracking('TrackOnProjectTemplateOpen', designDetails, trackingDetails, false);
        await this.trackOnTemplateChange(designDetails, trackingDetails);

        return trackingDetails;
    }

    public async trackOnTemplateClose(designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        if (!environment.trackingEnabled) {
            return;
        }

        await this.sendTracking('TrackOnProjectTemplateClose', designDetails, trackingDetails, true);
    }

    public async trackOnDesignOpen(designDetails: DesignDetails, openType: OpenType): Promise<TrackingDetails> {
        const trackingDetails = this.createTrackingDetails(openType);

        if (!environment.trackingEnabled) {
            return trackingDetails;
        }

        await this.sendTracking('TrackOnProjectDesignOpen', designDetails, trackingDetails, false);
        await this.trackOnDesignChange(designDetails, trackingDetails);

        return trackingDetails;
    }

    public async trackOnDesignClose(designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        if (!environment.trackingEnabled) {
            return;
        }

        await this.sendTracking('TrackOnProjectDesignClose', designDetails, trackingDetails, true);
    }

    public async trackOnDesignChange(designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        if (!environment.trackingEnabled) {
            return;
        }

        await this.sendTracking('TrackOnProjectDesignChange', designDetails, trackingDetails, true);
    }

    public async trackOnTemplateChange(designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        if (!environment.trackingEnabled) {
            return;
        }

        await this.sendTracking('TrackOnProjectTemplateChange', designDetails, trackingDetails, true);
    }

    public async trackOnCloseBrowserUnloadEvent(designDetails: DesignDetails, trackingDetails: TrackingDetails, isTemplate: boolean): Promise<void> {
        const url = isTemplate ? 'TrackOnProjectTemplateCloseSync' : 'TrackOnProjectDesignCloseSync';

        await this.sendTrackingOnBrowserUnloadEvent(url, designDetails, trackingDetails);
    }

    public createTrackingDetails(openType: OpenType): TrackingDetails {
        return {
            openTime: Date.now(),
            openType,
            counters: {
                approvalViewed: 0,
                reportCreated: 0,
                reportCreatedWithDefaultTemplate: 0,
                reportCreatedWithUserTemplate: 0,
                reportCreatedWithCustomTemplate: 0,
                designUndo: 0,
                designRedo: 0,
                designExport: 0,
                designDuplicate: 0,
                designImport: 0,
                headerMenuOpened: 0,
                headerOnlineTechnicalInformation: 0,
                specificationTextExport: 0
            }
        };
    }

    public async sendTracking(url: string, designDetails: DesignDetails, trackingDetails: TrackingDetails, includeActivities: boolean): Promise<void> {
        try {
            const absoluteUrl = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/${url}`;

            const data = this.prepareTrackingData(designDetails, trackingDetails, includeActivities);

            if (!data) {
                return;
            }

            const request = new HttpRequest<TrackOnOpenRequest>('POST', absoluteUrl, data, {
                responseType: 'json'
            });

            await this.apiService.request(request, { supressErrorMessage: true });
        }
        catch (error) {
            // tracking should fail silently
            console.error(error);
        }
    }

    public async sendTrackingOnBrowserUnloadEvent(url: string, designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        const absoluteUrl = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/${url}`;
        const data = this.prepareTrackingData(designDetails, trackingDetails, true);

        if (!data) {
            return;
        }

        const httpHeaders = this.userService.getHeaders(absoluteUrl, true);
        const jsonData = data != null
            ? JSON.stringify(data)
            : '';

        // Normally, when a document is unloaded, all associated network requests are aborted.
        // But the keepalive option tells the browser to perform the request in the background, even after it leaves the page.
        // So this option is essential for our request to succeed.
        // https://javascript.info/fetch-api#keepalive
        await trySendingUsingFetch(absoluteUrl, httpHeaders, jsonData);
    }

    private prepareTrackingData(DesignDetails: DesignDetails, trackingDetails: TrackingDetails, includeActivities: boolean): TrackOnOpenRequest | undefined {
        const trackingData = this.createTrackingData(trackingDetails, DesignDetails, includeActivities);

        // skip tracking if disabled
        if (!environment.trackingEnabled) {
            return;
        }

        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const data: TrackOnOpenRequest = {
            UseDirectTracking: true,
            DesignType: trackingData.designTypeId,
            TrackingData: {
                userDetails,
                uiVersion: this.sharedEnvironmentService.data?.applicationVersion,
                designTypeName: 'Glass',
                activities: trackingData.activities
            },
            DesignId: trackingData.designId,
            TemplateId: trackingData.templateId
        };

        return data;
    }

    private getUserDetails(timezoneOffset: number): UserDetails {
        const srcData = this.commonTrackingService.getTrackingUserData(timezoneOffset);

        return {
            BrowserType: srcData.BrowserType,
            OperatingSystem: srcData.OperatingSystem,
            TimezoneOffset: srcData.TimezoneOffset,
            IPAddress: srcData.UserIpAddress,
            UserName: srcData.UserName,
            UserId: srcData.UserId,
            DiagnosticsAgreement: srcData.DiagnosticsAgreement,
            CustomerId: srcData.CustomerId,
            CustomerOriginId: srcData.CustomerOriginId,
            SalesOrg: srcData.SalesOrg,
            CustomerType: srcData.CustomerType,
            License: srcData.License,
            CountryOfResidence: srcData.CountryOfResidence,
            CustomerCountry: srcData.CustomerCountry,
            BuyingCustomerOriginId: srcData.BuyingCustomerOriginId,
            BuyingSalesOrg: srcData.BuyingSalesOrg,
            BuyingCustomerType: srcData.BuyingCustomerType,
            IsTrial: srcData.IsTrial
        };
    }

    private createTrackingData(trackingDetails: TrackingDetails, designDetails: DesignDetails, includeActivities: boolean): TrackingData {
        let activities: TrackingActivities = {};

        if (includeActivities) {
            activities = {
                // TODO TEAM: copy logic for below functions from SP module when needed
                //...this.getTrackingActivitiesForSimpleProperties(designDetails, propertyInfos),
                //...this.getTrackingActivitiesCustom(designDetails)
            };
        }

        const commonTrackingData = this.commonTrackingData(trackingDetails, designDetails, includeActivities);
        return {
            ...commonTrackingData,
            designTypeId: designDetails.designTypeId,
            activities: {
                ...commonTrackingData.activities,
                ...activities
            }
        };
    }

    private commonTrackingData(trackingDetails: TrackingDetails, designDetails: DesignDetails, includeActivities: boolean): TrackingData {
        const activities: Record<string, string | undefined> = {};

        if (includeActivities) {
            activities['DesignId'] = designDetails.designId?.toString();
            activities['TemplateId'] = designDetails.templateId?.toString();
            activities['ApplicationMode'] = 'online';
            activities['LANGUAGE'] = this.localizationService.selectedLanguage;
            activities['REGION'] = designDetails.commonRegion.displayKey;
            activities['ProjectOpenType'] = trackingDetails.openType;

            const elapsedMs = Date.now() - trackingDetails.openTime;
            const elapsedSeconds = Math.floor((elapsedMs / 1000) % 60);
            const elapsedMinutes = Math.floor((elapsedMs / 60000) % 60);
            const elapsedHours = Math.floor(elapsedMs / 3600000);

            activities['SessionTime'] = `${elapsedHours}h:${elapsedMinutes}m:${elapsedSeconds}s`;

            // counters
            for (const _counterId in trackingDetails.counters) {
                const counterId = _counterId as TrackingCounterId;
                const counterName = counterNames[counterId];
                const counter = trackingDetails.counters[counterId];

                activities[counterName] = counter.toString();
            }
        }

        return {
            designTypeId: designDetails.designTypeId,
            designId: designDetails.designId,
            templateId: designDetails.templateId,
            activities
        };
    }
}
