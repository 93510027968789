import { Injectable } from '@angular/core';
import { IUserMenuFavorites } from '@profis-engineering/pe-ui-common/entities/favorites';
import { MenuType } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { FavoritesServiceBase, FavoritesServiceInjected } from '@profis-engineering/pe-ui-common/services/favorites.common';
import { designTypes } from './design.service';

// TODO FILIP: user-settings is still not modularized
// we have to add code to user-settings when we create a new module
const menu3DGlass = 'Menu3DGlass';

const favoriteTypes: Record<number, string> = {
    [designTypes.main.id]: menu3DGlass,
};

@Injectable({
  providedIn: 'root'
})
export class FavoritesService extends FavoritesServiceInjected {
    public override setBaseService(baseService: FavoritesServiceBase): void {
        super.setBaseService(baseService);

        this.dataChange.subscribe(data => this.initFavorites(data as IUserMenuFavorites));

    }

    public getMenuRegionIdFavorites(id: string, designType: number): string | undefined {
        // TODO FILIP: fix pe-ui to only call this for sp module
        if (designType in favoriteTypes) {
            return id;
        }

        return undefined;
    }

    public getFavoritesType(menuType: MenuType, designType: number) {
        // TODO FILIP: fix pe-ui to only call this for sp module
        if (menuType == MenuType.Menu3D) {
            return favoriteTypes[designType];
        }

        return undefined;
    }

    private initFavorites(data: IUserMenuFavorites) {
        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, designTypes.main.id),
            data?.favorites?.[menu3DGlass]
        );
    }

    private getInfo(menuType: MenuType, designType: number) {
        const favoritesType = this.getFavoritesType(menuType, designType);
        if (favoritesType == null) {
            throw new Error('Could not find favorites type.');
        }

        if (this.infoBase[favoritesType] == null) {
            this.infoBase[favoritesType] = {
                favoritesType,
                dbFavorites: [],
                favorites: [],
                setDefer: new Deferred<string[]>(),
                updateId: undefined,
                updating: false
            };
        }

        return this.infoBase[favoritesType];
    }
}
